.todoItems {
    position: relative;
    border-top: 1px solid #00000036;
    padding: 10px
}
.todoItems:last-child {
    border-bottom: 1px solid #00000036;
}

.todoItems:first-child {
    border-top: none;
}
.todoDone{
    text-decoration: line-through;
    opacity: .5;
}
.todoItems img {
    top: 10px;
    bottom: 0px;
    width: 25px;
    height: 25px;
    position: absolute;
}
.todoItems p {
    padding-left: 30px;
    margin: 2px;
}
.todoItems img.right {
    right: 15px;
    width: 15px;
    position: absolute;
    display: none
}
.todoItems:hover img.right {
    display: inline-block
}