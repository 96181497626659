.App-header img{
    width: 25px;
    height: 25px;
}
header.header {
    padding: 5px 0px;
}
.header input[type="text"] {
    width: 98%;
    padding: 10px 0px;
    margin: 10px 0px;
    margin-bottom: 0px;
    border: 1px solid #ded5d5;
    padding-left: 10px;
}