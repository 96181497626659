.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}


.App-link {
  color: #09d3ac;
}
.application {
  min-width: 230px;
  max-width: 550px;
  margin: 0 auto;
  background: #fff;
  padding: 12px 0px;
  text-align: left;
  border-radius: 6px;
  box-shadow: 5px 3px 10px 4px #0000001a;
}

body {
  background: #e2e0e0;
}