footer.footer {
    position: relative;
    padding: 6px 10px;
    margin: 10px;
}
.footer div{
    top: 0px;
    position: absolute;
}
.footer .center{
    left: 0px;
    right: 0px;
    text-align: center
}
.footer .right{
    right: 0px;
}
.footer span{
    padding-right: 5px;
}
.footer span.fillter {
    color: inherit;
    margin: 3px;
    padding: 3px 7px;
    text-decoration: none;
    border: 1px solid transparent;
    border-radius: 3px;
}
.footer span.fillter.active{
    border-color: rgba(175, 47, 47, 0.2);
}
.footer button {
    cursor: pointer;
    border: 1px solid #b3adad;
}

.footer button:hover {
    background: #fff;
}